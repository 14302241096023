import carousel from './components/image/carousel/carousel';
import stickyMenu from './components/navigation/sticky-menu/sticky-menu';
import showMore from './components/accordion/show-more/show-more';
import modal from './components/modal/modal';

window.Axiscom = {
  carousel,
  stickyMenu,
  showMore,
  modal,
};

export {
  carousel,
  stickyMenu,
  showMore,
  modal,
};
