function modal(rootElement, btn) {
  const modalSection = rootElement;
  const popUp = modalSection.getElementsByClassName('modal')[0];
  const cancel = modalSection.getElementsByClassName('modal__close')[0];
  const dialog = modalSection.getElementsByClassName('modal__dialog')[0];
  btn.addEventListener('click', () => {
    popUp.classList.add('show');
  });
  cancel.onclick = function () {
    popUp.classList.remove('show');
  };
  window.onclick = function (event) {
    if (event.target === dialog) {
      popUp.classList.toggle('show');
    }
  };
}

export default modal;
