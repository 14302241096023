// The following if-statement is a polyfill to make NodeList forEach work in IE11.
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

function changeImage(index, thumbs, images) {
  thumbs.forEach((thumb, currentIndex) => {
    if (currentIndex === index) {
      thumb.classList.add('carousel__thumb--active');
    } else {
      thumb.classList.remove('carousel__thumb--active');
    }
  });

  images.forEach((image, currentIndex) => {
    if (currentIndex === index) {
      image.classList.add('carousel__main-item--active');
    } else {
      image.classList.remove('carousel__main-item--active');
    }
  });
}

function carousel(root) {
  const thumbs = root.querySelectorAll('.carousel__thumb');
  const images = root.querySelectorAll('.carousel__main-item');

  thumbs.forEach((thumb, index) => {
    thumb.addEventListener('click', (event) => {
      event.preventDefault();
      changeImage(index, thumbs, images);
    });
  });
}

export default carousel;
