/**
 * Disable tabindex on normally focusable elements that are hidden behind the overflow: hidden fold.
 * This function needs to be called repeated times since new elements might be added dynamically.
 *
 * @param {Element} outer The container element in which to search for focusable elements.
 * @param {Boolean} allTabbable Boolean that is true if all content is visible.
 */
function toggleTabindex(outer, allTabbable) {
  const links = outer.querySelectorAll('a, button, video, input');

  if (!links) {
    return;
  }

  if (allTabbable) {
    // Section is fully opened so all interactive elements should be tabbable.
    for (let i = 0; i < links.length; i += 1) {
      links[i].removeAttribute('tabindex');
    }
    return;
  }

  // Some interactive elements are hidden and should not be tabbable. Let's find them.
  for (let i = 0; i < links.length; i += 1) {
    const linkBottom = links[i].getBoundingClientRect().bottom + window.pageYOffset;
    const containerBottom = outer.getBoundingClientRect().bottom + window.pageYOffset;
    if (linkBottom < containerBottom) {
      // Link is visible, above the bottom of the outer element, thus should be tabbable.
      links[i].removeAttribute('tabindex');
    } else {
      // Link is invisible, below the bottom of the outer element, thus should not be tabbable.
      links[i].setAttribute('tabindex', '-1');
    }
  }
}

/**
 * Create the link that is used to toggle visibility.
 *
 * @param element The accordion section element.
 * @returns {Element}
 */
function createToggleLink(element) {
  const { toggleText } = element.dataset;
  const toggleOuter = document.createElement('div');
  const toggleLink = document.createElement('a');
  const toggleIcon = document.createElement('span');
  toggleOuter.classList.add('show-more__toggle-outer');
  toggleLink.classList.add('show-more__toggle');
  toggleIcon.classList.add('show-more__expand');
  toggleIcon.appendChild(document.createTextNode('+'));
  toggleLink.setAttribute('href', '#');
  toggleLink.appendChild(document.createTextNode(toggleText));
  toggleLink.appendChild(toggleIcon);
  toggleOuter.appendChild(toggleLink);
  return toggleOuter;
}

/**
 * Initiate Show More-accordion.
 *
 * @param {Element} element The element on which to apply the accordion functionality.
 * @param {int} height The desired max-height of the element in pixels.
 */
function showMore(element, maxHeight = 500) {
  // Delay needed for CSS animation to finish to correctly calculate offsets.
  // Should equal CSS animation duration.
  const animationDelay = 500;

  // Create toggler & get elements from DOM.
  const showMoreToggler = createToggleLink(element);
  element.appendChild(showMoreToggler);
  const outer = element.querySelector('.show-more__outer');
  const inner = element.querySelector('.show-more__inner');

  // Activate the max-height with overflow: hidden dynamically with JS.
  outer.classList.add('show-more__outer--active');
  outer.style.maxHeight = `${maxHeight}px`;

  // Initial set tabindex for visible vs invisible elements.
  setTimeout(() => { toggleTabindex(outer, false); }, animationDelay);

  // Set max-height on toggler to be able to animate it.
  showMoreToggler.style.maxHeight = `${showMoreToggler.clientHeight}px`;

  showMoreToggler.querySelector('.show-more__toggle').addEventListener('click', (e) => {
    e.preventDefault();
    if (!outer.classList.contains('show-more--open')) {
      outer.classList.add('show-more--open');
      // Set temporary new max-height to animate to.
      outer.style.maxHeight = `${inner.scrollHeight}px`;
      setTimeout(() => {
        // When animation is done, update tabindex, remove toggle link and remove
        // max-height since it might lead to hidden content if window is resized.
        toggleTabindex(outer, true);
        showMoreToggler.removeChild(e.target);
        outer.style.maxHeight = null;
      }, animationDelay);
    }
  });
}

export default showMore;
