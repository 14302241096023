const STICKY_TOP_CLASS = 'sticky-menu--sticky-top';
const STICKY_FIXED_TOP_CLASS = 'sticky-menu--sticky-fixed-top';
const STICKY_BOTTOM_CLASS = 'sticky-menu--sticky-bottom';
const ACTIVE_MENU_ITEM_CLASS = 'tab__link--active';
let offsetFromTop = 0;

const states = {
  FIXED_TOP: 'fixed top',
  ABSOLUTE_TOP: 'absolute top',
  ABSOLUTE_BOTTOM: 'absolute bottom',
};

/**
 * Get one of three states of the sticky menu depending on its position
 *
 * - FIXED_TOP, if the view port is scrolled passed the container top but hasn't reached the bottom
 *   of the container
 * - ABSOLUTE_BOTTOM, if the bottom of the menu has reached the bottom of the container
 * - ABSOLUTE_TOP, if the top of the view port hasn't reached the top of the container
 * @param {Number} deltaTop The delta between the top of the view port and the top of container
 * @param {Number} deltaBottom The delta between the botton of the menu element and the bottom of
 *   the container
 * @param {Number} offset
 */
function getStickyPosition(deltaTop, deltaBottom, offset) {
  if (deltaTop - offset < 0 && deltaBottom - offset > 0) {
    return states.FIXED_TOP;
  }

  if (deltaBottom - offset <= 0) {
    return states.ABSOLUTE_BOTTOM;
  }

  return states.ABSOLUTE_TOP;
}

function updateStickyPosition(sticky, state, offset) {
  /* eslint no-param-reassign: ["error", { "props": false }] */
  switch (state) {
    case states.FIXED_TOP:
      // We're scrolled down past the wrapper's top, but still above wrapper's bottom.
      // Let's make the menu sticky to top,
      sticky.classList.add(STICKY_FIXED_TOP_CLASS);
      sticky.classList.remove(STICKY_TOP_CLASS);
      sticky.classList.remove(STICKY_BOTTOM_CLASS);
      sticky.style.top = `${offset}px`;
      break;
    case states.ABSOLUTE_BOTTOM:
      // We're scrolled down past the wrapper's top, but also past the wrapper's bottom.
      // Let's make the menu sticky to wrapper's bottom, so that it will eventually
      // disappear outside the viewport.
      sticky.classList.remove(STICKY_FIXED_TOP_CLASS);
      sticky.classList.remove(STICKY_TOP_CLASS);
      sticky.classList.add(STICKY_BOTTOM_CLASS);
      sticky.style.top = null;
      break;
    case states.ABSOLUTE_TOP:
    default:
      // We're above the sticky menu wrapper. Sticky menu remains inactive.
      sticky.classList.remove(STICKY_FIXED_TOP_CLASS);
      sticky.classList.remove(STICKY_BOTTOM_CLASS);
      sticky.classList.add(STICKY_TOP_CLASS);
      sticky.style.top = 0;
  }
}

function setActiveClass(links, currentMobile, sticky) {
  // Get top and bottom position for each section. Make the corresponding menu item active if the
  // top value equals or is less than the sticky menu's bottom position, and the bottom value is
  // larger than the height of the sticky menu plus any offset.
  const stickyMenuBottom = sticky.getBoundingClientRect().bottom;
  links.forEach((link) => {
    const section = document.querySelector(link.hash);
    let { top, bottom } = section.getBoundingClientRect();
    top = Math.floor(top);
    bottom = Math.floor(bottom);
    if (top <= stickyMenuBottom && bottom > stickyMenuBottom) {
      link.classList.add(ACTIVE_MENU_ITEM_CLASS);
      // eslint-disable-next-line no-param-reassign
      currentMobile.innerHTML = link.innerHTML;
    } else {
      link.classList.remove(ACTIVE_MENU_ITEM_CLASS);
    }
  });
}

function handleScroll(sticky, container, links, currentMobile, offset) {
  const { top, bottom } = container.getBoundingClientRect();
  const deltaTop = top;
  const deltaBottom = bottom - sticky.offsetHeight;
  const position = getStickyPosition(deltaTop, deltaBottom, offset);

  updateStickyPosition(sticky, position, offset);
  setActiveClass(links, currentMobile, sticky);
}

function handleLinkClick(e, tabs) {
  if (window.scrollTo) {
    // If scrollTo is not supported, browsers handle this natively as regular anchor links.
    // However no care will be taken to offsetFromTop in such browsers.
    e.preventDefault();

    // Prepare offset to scroll to.
    const { hash } = e.target;
    const { top } = document.querySelector(hash).getBoundingClientRect();
    const goto = top + window.pageYOffset - offsetFromTop;

    window.scrollTo(0, goto);

    // Since we use prevent default, we need to update the url hash manually.
    window.history.replaceState(undefined, '', hash);
  }

  // Hide mobile menu if expanded.
  tabs.classList.remove('tabs--visible');
}

/**
 * Initiate sticky menu with scrollspy
 *
 * @param {Element} sticky The sticky menu element
 * @param {Element} container The element which the sticky menu is able to float in
 */
function stickyMenu(sticky, container) {
  // Handle mobile version of sticky menu.
  const currentMobile = sticky.querySelector('.sticky-menu__current');
  const tabs = sticky.querySelector('.tabs');
  currentMobile.addEventListener('click', () => {
    tabs.classList.toggle('tabs--visible');
  });

  // Get the links in the sticky menu.
  const links = tabs.querySelectorAll('a');

  // When user clicks link, scroll to position and close mobile menu.
  links.forEach((link) => {
    link.addEventListener('click', (event) => {
      handleLinkClick(event, tabs);
    });
  });

  // Initial run, needed in case page is loaded scrolled down or with an active anchor.
  handleScroll(sticky, container, links, currentMobile, offsetFromTop);

  window.addEventListener('scroll', () => {
    handleScroll(sticky, container, links, currentMobile, offsetFromTop);
  });

  return {
    setOffset: (val) => {
      // "Exposed API" to allow the sticky menu to have an extra offset from the top.
      offsetFromTop = val;
      handleScroll(sticky, container, links, currentMobile, offsetFromTop);
    },
  };
}

export default stickyMenu;
